var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c("img", {
      attrs: {
        src: _vm.lineNotifyLogoUrl,
        alt: "line notify icon",
        width: "50px",
      },
    }),
    _c(
      "span",
      {
        staticClass: "push-remind-modal-title",
      },
      [_vm._v(_vm._s(_vm.$t("notification.line_notify")))]
    ),
    _c("br"),
    _c(
      "div",
      {
        staticClass: "push-remind-status-box",
      },
      [
        _c("table", [
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "push-remind-type-title",
              },
              [_vm._v(_vm._s(_vm.$t("notification.class_remind")))]
            ),
            _c(
              "td",
              [
                _c("notify-bind-status", {
                  attrs: {
                    bindStauts:
                      _vm.vipProfileComplete.social_media.bind.line_notify,
                    notifyStatus: _vm.notifyInfo.line_notify_booking,
                    notifyType: "lineNotify",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "push-remind-type-title",
              },
              [_vm._v(_vm._s(_vm.$t("notification.notify_of_right")))]
            ),
            _c(
              "td",
              [
                _c("notify-bind-status", {
                  attrs: {
                    bindStauts:
                      _vm.vipProfileComplete.social_media.bind.line_notify,
                    notifyStatus: _vm.notifyInfo.line_notify_of_right,
                    notifyType: "lineNotifyRight",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "push-remind-type-title",
              },
              [_vm._v(_vm._s(_vm.$t("notification.operate")))]
            ),
            _c(
              "td",
              [
                _c("line-notify-operation", {
                  attrs: {
                    bindStauts:
                      _vm.vipProfileComplete.social_media.bind.line_notify,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("img", {
      staticClass: "logo-img",
      attrs: {
        src: _vm.officialWebsiteNotifyLogoUrl,
        alt: "wuwow icon",
      },
    }),
    _c(
      "span",
      {
        staticClass: "push-remind-modal-title",
      },
      [_vm._v(_vm._s(_vm.$t("notification.official_website_notify")))]
    ),
    _c("br"),
    _vm._v("(" + _vm._s(_vm.$t("notification.web_open_only")) + ")"),
    _c(
      "div",
      {
        staticClass: "push-remind-status-box",
      },
      [
        _c(
          "div",
          {
            staticClass: "push-remind-status-box",
          },
          [
            _c("table", [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "push-remind-type-title",
                  },
                  [_vm._v(_vm._s(_vm.$t("notification.class_remind")))]
                ),
                _c(
                  "td",
                  [
                    _c("notify-bind-switch", {
                      attrs: {
                        notifyStatus: _vm.notifyInfo.notify_booking,
                        notifyType: "browserNotify",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "push-remind-type-title",
                  },
                  [_vm._v(_vm._s(_vm.$t("notification.notify_of_right")))]
                ),
                _c(
                  "td",
                  [
                    _c("notify-bind-switch", {
                      attrs: {
                        notifyStatus: _vm.notifyInfo.notify_of_right,
                        notifyType: "browserNotifyRight",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }