var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.bindStauts
        ? _c(
            "b-button",
            {
              attrs: {
                variant: "danger",
              },
              on: {
                click: function ($event) {
                  return _vm.unbindLineNotify()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("notification.unbind_line_notify")))]
          )
        : _c(
            "div",
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-setblue",
                  on: {
                    click: function ($event) {
                      return _vm.bindLineNotify()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("notification.bind_line_notify")))]
              ),
              _c("br"),
              _c("span", [
                _vm._v(
                  "(" + _vm._s(_vm.$t("notification.refresh_after_bind")) + ")"
                ),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }