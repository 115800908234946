var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        id: "notification_push_setting",
        size: "lg",
        scrollable: "",
        title: _vm.$t("notification.notification_push_settings"),
        "hide-footer": "",
      },
      on: {
        hide: function ($event) {
          return _vm.hideNotificationPushSettingModal()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "pc-show",
        },
        [
          _c("pc-layout", {
            attrs: {
              vipProfileComplete: _vm.vipProfileComplete,
              notifyInfo: _vm.notifyInfo,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "mobile-show text-center",
        },
        [
          _c("mobile-layout", {
            attrs: {
              vipProfileComplete: _vm.vipProfileComplete,
              notifyInfo: _vm.notifyInfo,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }