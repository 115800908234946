var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "skeleton",
    {
      attrs: {
        loading: _vm.loading,
      },
    },
    [
      _c(
        "template",
        {
          slot: "skeleton-template",
        },
        [_c("skeleton-template")],
        1
      ),
      _c(
        "template",
        {
          slot: "origin-template",
        },
        [
          _c(
            "div",
            {
              staticClass: "reg-profile",
            },
            [
              _c(
                "div",
                {
                  staticClass: "wuwow-card",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "wuwow-card-body",
                    },
                    [
                      _c("porfile-info", {
                        attrs: {
                          vipProfileComplete: _vm.vipProfileComplete,
                          vipOwnBD: _vm.vipOwnBD,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.accountRole !== "parent"
                    ? _c(
                        "div",
                        {
                          staticClass: "wuwow-card-footer text-center",
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "lnk-profile-edit",
                              attrs: {
                                to: {
                                  path: "/dojo/account/profile/edit",
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: "pen",
                                },
                              }),
                              _vm._v(_vm._s(_vm.$t("edit"))),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c("notification-push-setting", {
                attrs: {
                  vipProfileComplete: _vm.vipProfileComplete,
                },
                on: {
                  closenotificationpushsettingmodal:
                    _vm.closeNotificationPushSettingModal,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }