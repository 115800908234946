var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "form",
    {
      attrs: {
        action: _vm.appBaseApi + _vm.creditCardUrl,
        method: "post",
      },
    },
    [
      _c("input", {
        staticClass: "btn btn-primary",
        attrs: {
          type: "submit",
        },
        domProps: {
          value: _vm.$t("click_me_to_bind_credit"),
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.vipAccountBaseProfile.user_id,
            expression: "vipAccountBaseProfile.user_id",
          },
        ],
        attrs: {
          type: "hidden",
          name: "uid",
        },
        domProps: {
          value: _vm.vipAccountBaseProfile.user_id,
        },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.vipAccountBaseProfile, "user_id", $event.target.value)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.vipAccountBaseProfile.user_account,
            expression: "vipAccountBaseProfile.user_account",
          },
        ],
        attrs: {
          type: "hidden",
          name: "uac",
        },
        domProps: {
          value: _vm.vipAccountBaseProfile.user_account,
        },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(
              _vm.vipAccountBaseProfile,
              "user_account",
              $event.target.value
            )
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }